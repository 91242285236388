import PropTypes from "prop-types";
import React, { useEffect, useRef, useState,Suspense, lazy } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { multilanguage, loadLanguages } from "redux-multilanguage";
//import { connect } from "react-redux";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";
import { fetchProducts } from "./redux/actions/productActions";
import { updateWishlistLang } from "./redux/actions/wishlistActions";
import { updateCartLang } from "./redux/actions/cartActions";
import { updateEnquiryLang } from "./redux/actions/enquiryActions";
import { AuthProvider } from "./contexts/AuthContext";
import PrivateRoute from "./PrivateRoute";
import io from 'socket.io-client';
//import io from 'socket.io-client';
//import { changeLanguage } from "redux-multilanguage";
//import CheckConnection from "./components/CheckConnection";
//import products from "./data/products.json";
// home pages

const HomeFashion = lazy(() => import("./pages/home/HomeFashion"));
const Product = lazy(() => import("./pages/shop-product/Product"));
/*const ProductTabLeft = lazy(() =>
  import("./pages/shop-product/ProductTabLeft")
);
const ProductTabRight = lazy(() =>
  import("./pages/shop-product/ProductTabRight")
);
const ProductSticky = lazy(() => import("./pages/shop-product/ProductSticky"));
const ProductSlider = lazy(() => import("./pages/shop-product/ProductSlider"));
const ProductFixedImage = lazy(() =>
  import("./pages/shop-product/ProductFixedImage")
); */
// blog pages
/*const BlogStandard = lazy(() => import("./pages/blog/BlogStandard"));
const BlogNoSidebar = lazy(() => import("./pages/blog/BlogNoSidebar"));
const BlogRightSidebar = lazy(() => import("./pages/blog/BlogRightSidebar"));
const BlogDetailsStandard = lazy(() =>
  import("./pages/blog/BlogDetailsStandard")
); */

// shop pages
const ShopGridStandard = lazy(() => import("./pages/shop/ShopGridStandard"));
// other pages
const About = lazy(() => import("./pages/other/About"));
const Contact = lazy(() => import("./pages/other/Contact"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const MyOrder = lazy(() => import("./pages/other/MyOrder"));
const MyOrderItems = lazy(() => import("./pages/other/MyOrderItems"));
const Login = lazy(() => import("./pages/other/Login"));
const Signup = lazy(() => import("./pages/other/Signup"));
const ForgotPassword = lazy(() => import("./pages/other/ForgotPassword"));

const Cart = lazy(() => import("./pages/other/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Enquiry = lazy(() => import("./pages/other/Enquiry"));
const Checkout = lazy(() => import("./pages/other/Checkout"));
const Success = lazy(() => import("./pages/other/Success"));
const Cancel = lazy(() => import("./pages/other/Cancel"));
const NotFound = lazy(() => import("./pages/other/NotFound"));
const Delivery = lazy(() => import("./pages/other/Delivery"));
const Return = lazy(() => import("./pages/other/Return"));
const Payment = lazy(() => import("./pages/other/Payment"));
const PayMePage = lazy(() => import("./pages/other/PayMePage"));
const App = (props) => {  
//  const [jsonupdate, setJsonUpdate] = useState([]);
  const socketRef=useRef(null);
/*  useEffect(() => {
   console.log("f1")
   fetch(process.env.REACT_APP_DATAURL+'/jsonupdate.json?'+Math.random())   //, {signal: controller.signal})
     .then(response => {
       if (!response.ok) {
        throw new Error ('Network response was not ok')
       }
       const contentType= response.headers.get('content-type');
       if (!contentType || !contentType.includes('application/json')) {
        throw new TypeError('Response was not Json');
       }
       return response.json();
     })
       .then(data => { 
              if (JSON.stringify(data)!==JSON.stringify(jsonupdate)){
               setJsonUpdate(data);
              }
            })          
       .catch(error => {
         // handle error
          console.log(error.message)
       })     
  }) */

  /*useEffect(() => {
    const interval = setInterval(() => {
      fetch(process.env.REACT_APP_DATAURL+'/jsonupdate.json?'+Math.random())   //, {signal: controller.signal})
     .then(response => {
       if (!response.ok) {
        throw new Error ('Network response was not ok')
       }
       const contentType= response.headers.get('content-type');
       if (!contentType || !contentType.includes('application/json')) {
        throw new TypeError('Response was not Json');
       }
       return response.json();
     })
       .then(data => { 
              if (JSON.stringify(data)!==JSON.stringify(jsonupdate)) {
               const curtime=new Date(); 
               localStorage.setItem("refreshTime", curtime.getTime())
               setJsonUpdate(data);      
              // setTimeout(setRefresh(false),1500);      
              }
            })
          
       .catch(error => {
         // handle error
          console.log(error.message)
       });
     },20000);        
    
      fetch(process.env.REACT_APP_DATAURL+'/products.json?'+Math.random())   //, {signal: controller.signal})
      .then(response => {
        if (!response.ok) {
         throw new Error ('Network response was not ok')
        }
        const contentType= response.headers.get('content-type');
        if (!contentType || !contentType.includes('application/json')) {
         throw new TypeError('Response was not Json');
        }
        return response.json();
      })
      .then(data => { props.dispatch(updateWishlistLang(data)); return (data)})
      .then(data => { props.dispatch(updateCartLang(data)); return (data)})
      .then(data => { props.dispatch(updateEnquiryLang(data)); return (data)})
      .then(data => { props.dispatch(fetchProducts(data))})
      .catch(error => {
        console.log(error.message)
      })  

      props.dispatch(
        loadLanguages({
          languages: { 
            zh: require("./translations/traditional.json"),
            en: require("./translations/english.json")
          }
        })
      );
    
    return () => clearInterval(interval);

  },[jsonupdate]); */

  useEffect(() => {
 
    const socket=io(process.env.REACT_APP_BASEURL);
    socketRef.current=socket;
  //  console.log(socketRef.current);
    socketRef.current.on('update-products-notification', (res) =>{     
    //  console.log(socketRef.current);
      fetch(process.env.REACT_APP_DATAURL+'/products.json?'+Math.random())   //, {signal: controller.signal})
      .then(response => {
       if (!response.ok) {
        throw new Error ('Network response was not ok')
       }
       const contentType= response.headers.get('content-type');
       if (!contentType || !contentType.includes('application/json')) {
        throw new TypeError('Response was not Json');
       }
       return response.json();
     })
     .then(data => { props.dispatch(updateWishlistLang(data)); return (data)})
     .then(data => { props.dispatch(updateCartLang(data)); return (data)})
     .then(data => { props.dispatch(updateEnquiryLang(data)); return (data)})
     .then(data => { props.dispatch(fetchProducts(data))})
     .catch(error => {
       console.log(error.message)
     })  
   
       props.dispatch(
         loadLanguages({
           languages: { 
             zh: require("./translations/traditional.json"),
             en: require("./translations/english.json")
           }
         })
       ); 
    })
    return () => {
      socketRef.current.disconnect();
    }      
  },[socketRef]);


  useEffect(() => {
   //   console.log("fetchProducts");
      fetch(process.env.REACT_APP_DATAURL+'/products.json?'+Math.random())   //, {signal: controller.signal})
     .then(response => {
      if (!response.ok) {
       throw new Error ('Network response was not ok')
      }
      const contentType= response.headers.get('content-type');
      if (!contentType || !contentType.includes('application/json')) {
       throw new TypeError('Response was not Json');
      }
      return response.json();
    })
    .then(data => { props.dispatch(updateWishlistLang(data)); return (data)})
    .then(data => { props.dispatch(updateCartLang(data)); return (data)})
    .then(data => { props.dispatch(updateEnquiryLang(data)); return (data)})
    .then(data => { props.dispatch(fetchProducts(data))})
    .catch(error => {
      console.log(error.message)
    })  
  
      props.dispatch(
        loadLanguages({
          languages: { 
            zh: require("./translations/traditional.json"),
            en: require("./translations/english.json")
          }
        })
      );
    }
 ,[]);


 // console.log(props.currentLanguageCode)
  return (
    <ToastProvider placement="bottom-left" autoDismissTimeout={1500}>
     <AuthProvider>
      <BreadcrumbsProvider>
        <Router>
          <ScrollToTop >
            <Suspense
              fallback={
                <div className="flone-preloader-wrapper">
                  <div className="flone-preloader">
                    <span></span>
                    <span></span>
                  </div>
                </div>
              }
            >
              <Switch>
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/"}
                  component={HomeFashion}
                />

                {/* Homepages */}
                <Route
                  path={process.env.PUBLIC_URL + "/home-fashion"}
                  component={HomeFashion}
                />
                

                {/* Shop pages */}

                <Route
                  path={process.env.PUBLIC_URL + "/shop/:id/:ftype"}
                  render={(routeProps) => (
                  <ShopGridStandard {...routeProps} key={routeProps.match.params.id} />
                )}
                  />                
                <Route
                  path={process.env.PUBLIC_URL + "/shop/:id"}
                  render={(routeProps) => (
                  <ShopGridStandard {...routeProps} key={routeProps.match.params.id} />
                )}
                  /> 

                <Route
                  path={process.env.PUBLIC_URL + "/shop"}
                  component={ShopGridStandard}
                />
                
                
                {/* Shop product pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/product/:id/:type"} 
                   render={(routeProps) => (
                    <Product key={routeProps.match.params.id} {...routeProps}/>
                   )}
                />                
                <Route
                  path={process.env.PUBLIC_URL + "/product/:id"} 
                   render={(routeProps) => (
                    <Product key={routeProps.match.params.id} {...routeProps}/>
                   )}
                />
               {/* <Route
                  path={process.env.PUBLIC_URL + "/product-tab-left/:id"}
                  component={ProductTabLeft}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/product-tab-right/:id"}
                  component={ProductTabRight}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/product-sticky/:id"}
                  component={ProductSticky}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/product-slider/:id"}
                  component={ProductSlider}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/product-fixed-image/:id"}
                  component={ProductFixedImage}
                   /> */}

                {/* Blog pages */}
               {/* <Route
                  path={process.env.PUBLIC_URL + "/blog-standard"}
                  component={BlogStandard}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/blog-no-sidebar"}
                  component={BlogNoSidebar}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/blog-right-sidebar"}
                  component={BlogRightSidebar}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/blog-details-standard"}
                  component={BlogDetailsStandard}
                  /> */}

                {/* Other pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/about"}
                  component={About}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/contact/:ctype"}
                  component={Contact}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/contact"}
                  component={Contact}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/account/:info"}
                  component={MyAccount}
                />  
                <Route
                  path={process.env.PUBLIC_URL + "/account"}
                  component={MyAccount}
                />                                                                 
                <PrivateRoute
                  path={process.env.PUBLIC_URL + "/login"}
                  component={Login}
                />
                <PrivateRoute
                  path={process.env.PUBLIC_URL + "/signup"}
                  component={Signup}
                />
                <PrivateRoute
                  path={process.env.PUBLIC_URL + "/forgot-password"}
                  component={ForgotPassword}
                />    
                <Route
                  path={process.env.PUBLIC_URL + "/my-order/:status"}
                  component={MyOrder}
                />                
                <Route
                  path={process.env.PUBLIC_URL + "/my-order"}
                  component={MyOrder}
                />                           
                <Route
                  path={process.env.PUBLIC_URL + "/my-orderitems"}
                  component={MyOrderItems}
                />              
                <Route
                  path={process.env.PUBLIC_URL + "/cart"}
                  component={Cart}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/wishlist"}
                  component={Wishlist}                
                />
                <Route
                  path={process.env.PUBLIC_URL + "/enquiry"}
                  component={Enquiry}
                />
                <Route                
                  path={process.env.PUBLIC_URL + "/checkout"}
                  component={Checkout}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/success/:id"}
                  component={Success}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/success"}
                  component={Success}
                />                
                <Route
                  path={process.env.PUBLIC_URL + "/cancel"}
                  component={Cancel}
                />                
                <Route
                  path={process.env.PUBLIC_URL + "/not-found"}
                  component={NotFound}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/delivery"}
                  component={Delivery}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/return"}
                  component={Return}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/payment"}
                  component={Payment}
                />   
                <Route
                  path={process.env.PUBLIC_URL + "/PayMePage"}
                  component={PayMePage}
                />                                             
                <Route exact component={NotFound} />
              </Switch>
            </Suspense>
          </ScrollToTop>
        </Router>
      </BreadcrumbsProvider>
      </AuthProvider>
    </ToastProvider>
  );
};

App.propTypes = {
  dispatch: PropTypes.func,
  refresh: PropTypes.bool,

 // fetchProducts: PropTypes.func,
 // updateEnquiryLang: PropTypes.func
};
/*
const mapStateToProps = state => {
  return {
    enquiryItems: state.enquiryData
  }
}

const mapDispatchToProps = dispatch => {
  //console.log('fetchproducts')
  return {
   updateEnquiryLang: (data) => {
    dispatch(updateEnquiryLang(data));
 },   
  }
} */
 

export default multilanguage(App);
